<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-1">
          Placement Yearly Stat
        </b-card-title>
        <b-card-sub-title class="mb-2">
          Placements Yearly Stat in comparison to last year
        </b-card-sub-title>
      </div>
      <u-icon icon="fi-rr-interrogation" size="24" class="text-muted cursor-pointer" v-b-popover.hover.left="
        `This section shows the comparison between this year(${batchValue}) placements and previous year(${batchValue - 1})`
      " />
    </b-card-header>

    <b-card-body class="pb-0">
      <div class="d-flex justify-content-start mb-3" v-if="placementMonthData">
        <div class="mr-2">
          <!-- <b-card-text class="mb-50 font-medium-1">
            Total Placements in {{ batchValue }}
          </b-card-text> -->
          <h3 class="font-weight-bolder d-flex flex-column  justify-content-center">
            <span class="font-medium-1">Placements in {{ batchValue }}
            </span>
            <span class="text-primary justify-content-center">{{
                placementMonthData.totalPlacements
            }}</span>
          </h3>
        </div>
        <div>
          <!-- <b-card-text class="mb-50 font-medium-1">
            Total Placements in {{ batchValue - 1 }}
          </b-card-text> -->
          <h3 class="font-weight-bolder d-flex flex-column justify-content-center">
            <span class="font-medium-1">Placements in {{ batchValue - 1 }}
            </span>
            <span class="justify-content-center"> {{ prevPlacementMonthData.totalPlacements }} </span>
          </h3>
        </div>
      </div>
      <!-- apex chart -->
      <vue-apex-charts ref="placementMonthRef" type="line" height="240" :options="salesLine.chartOptions"
        :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  VBPopover,
  BCardSubTitle,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
  },
  props: {
    placementMonthData: {
      type: Object,
    },
    prevPlacementMonthData: {
      type: Object
    },
    batchValue: {
      type: Number,
    },
    downloadTrigger: {
      type: Boolean
    },
  },
  directives: {
    "b-popover": VBPopover,
  },
  watch: {
    placementMonthData() {
      if (this.placementMonthData) {

        this.series = [
          {
            name: "This Year Placements",
            data: this.placementMonthData.placeMonthNo,
          },
          {
            name: "Last Year Placements",
            // data: [24,36,34,4,5,3,56,56,87,98,3,20],
            data: this.prevPlacementMonthData.placedMonthNo,

          },
        ];
        // this.$refs.placementMonthRef.refresh();
        this.$refs.placementMonthRef.updateSeries([
          {
            name: "This Year Placements",
            data: this.placementMonthData.placeMonthNo,
          },
          {
            name: "Last Year Placements",
            // data: [24,36,34,4,5,3,56,56,87,98,3,20]
            data: this.prevPlacementMonthData.placedMonthNo,
          },
        ])
      }
    },
    downloadTrigger() {
      if (this.downloadTrigger) {
        // this.$refs.placementMonthRef.updateOptions({
        //   dataLabels: {
        //     enabled: true,
        //     style: {
        //       fontSize: '14px',
        //       fontFamily: "'Mulish' , sans-serif",
        //       fontWeight: 'bold',
        //       colors: ["#043c57"]
        //     },

        //   },
        // })

      } else {
        console.log('problem in else')
        // this.$refs.placementMonthRef.updateOptions({
        //   dataLabels: {
        //     enabled: false,
        //   },
        // })
      }
    }
  },
  data() {
    return {
      series: [
        {
          name: "This Year Placements",
          data: [],
        },
        {
          name: "Last Year Placements",
          data: [],
        },
      ],
      salesLine: {

        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "line",
            // offsetX: -10,
          },
          stroke: {
            curve: "smooth",
            dashArray: [0, 12],
            width: [4, 3],
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
          // fill: {
          //   type: "gradient",
          //   gradient: {
          //     shade: "dark",
          //     inverseColors: false,
          //     gradientToColors: [$themeColors.primary, $themeColors.warning],
          //     shadeIntensity: 1,
          //     type: "horizontal",
          //     opacityFrom: 1,
          //     opacityTo: 1,
          //     stops: [0, 100, 100, 100],
          //   },
          // },
          noData: {
            text: "No Data found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#043c57",
              fontSize: "14px",
              fontFamily: "'Mulish' , sans-serif",
            },
          },
          markers: {
            size: 0,
            hover: {
              size: 5,
            },
          },
          xaxis: {
            labels: {
              style: {
                colors: "#043c57",
                fontSize: "1rem",
                fontFamily: "'Mulish' , sans-serif",
              },
            },
            axisTicks: {
              show: false,
            },
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "July",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
            axisBorder: {
              show: false,
            },
            tickPlacement: "on",
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#043c57",
                fontSize: "1rem",
                fontFamily: "'Mulish' , sans-serif",
              },
              formatter(val) {
                return val > 999 ? `${(val / 1000).toFixed(0)}k` : val;
              },
            },
          },
          grid: {
            borderColor: "#efefef",
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            fillSeriesColor: false,
            theme: "dark",
            style: {
              fontSize: "12px",
              fontFamily: "'Mulish' , sans-serif",
            },
            x: { show: false },
            shared: true
          },
        },
      },
    };
  },
};
</script>
