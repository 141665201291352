<template>
  <b-card no-body>
    <b-card-header>
      <!-- <div class="d-flex justify-content-between"> -->
      <div>
        <b-card-title class="mb-1">
          Placement Distribution
        </b-card-title>
        <b-card-sub-title>
          Placement by various {{ subtitleText }}
        </b-card-sub-title>
      </div>
      <u-icon icon="fi-rr-interrogation" size="24" class="text-muted cursor-pointer" v-b-popover.hover.left="`This section represents the distribution of all the placements by various ${subtitleText}`"/>
      <!-- </div> -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-form-group class="">
        <label>Filter by</label>
        <v-select
          v-model="selectedFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="chartSelections"
          @input="(val) => changingFilter(val.title)"
        />
      </b-form-group>

      <vue-apex-charts
        class="mt-2"
        ref="placementIndustryRef"
        type="donut"
        height="300"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardHeader,
  BFormGroup, VBPopover
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import { $themeColors, $chartColors } from "@themeConfig";
import axios from "@axios";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardHeader,
    BFormGroup,
    vSelect,
  },
  props: {
    placementIndustryData: {
      type: Object,
    },
    placementTypeData: {
      type: Object,
    },
    placementCourseData: {
      type: Object,
    },
    batchValue: {
      type: Number,
    },
    downloadTrigger: {
			type: Boolean
		},
  },
  directives: {
    'b-popover': VBPopover,
  },
  methods: {
    changingFilter(val) {
      if (val == "Industries") {
        if (this.placementIndustryData) {
          this.chartOptions.labels = this.placementIndustryData.labels;
          this.series = this.placementIndustryData.placeIndData;
          this.subtitleText = "Industries";
        }
      } else if (val == "Types") {
        if (this.placementTypeData) {
          this.chartOptions.labels = this.placementTypeData.labels;
          this.series = this.placementTypeData.placetypeData;
          this.subtitleText = "Job Functions";
        }
      } else if (val == "Courses") {
        if (this.placementCourseData) {
          this.chartOptions.labels = this.placementCourseData.labels;
          this.series = this.placementCourseData.placeCourse;
          this.subtitleText = "courses";
        }
      }
      this.$refs.placementIndustryRef.refresh();
    },
  },

  watch: {
    placementIndustryData() {
      var val = this.selectedFilter;
      if (val == "Industries") {
        if (this.placementIndustryData) {
          this.chartOptions.labels = this.placementIndustryData.labels;
          this.series = this.placementIndustryData.placeIndData;
          this.subtitleText = "Industries";
        }
      } else if (val == "Types") {
        if (this.placementTypeData) {
          this.chartOptions.labels = this.placementTypeData.labels;
          this.series = this.placementTypeData.placetypeData;
          this.subtitleText = "Job Functions";
        }
      } else if (val == "Courses") {
        if (this.placementCourseData) {
          this.chartOptions.labels = this.placementCourseData.labels;
          this.series = this.placementCourseData.placeCourse;
          this.subtitleText = "courses";
        }
      }
      this.$refs.placementIndustryRef.refresh();
    },
    downloadTrigger() {
			if (this.downloadTrigger) {
			this.$refs.placementIndustryRef.updateOptions({
					dataLabels: {
						enabled: true,
            formatter: function (val, opts) {
              console.log(opts.w.globals.series[opts.seriesIndex])
              return opts.w.globals.series[opts.seriesIndex]
            },
						style: {
							fontSize: '12px',
							fontFamily: "'Mulish' , sans-serif",
							fontWeight: 600,
							colors: ["#043c57"]
						},

					},
          legend: {
            show: true, position: 'bottom', fontSize: '10px',
            fontFamily: "'Mulish' , sans-serif",
            fontWeight: 400,
          },
				})
				
			} else {
				console.log('problem in else')
				this.$refs.placementIndustryRef.updateOptions({
					dataLabels: {
						enabled: false,
					},
          legend:{show:false}
				})
			}
		}
  },

  data() {
    return {
      chartData: [],
      selectedFilter: "Industries",
      subtitleText: "Industries",
      chartSelections: [
        { title: "Industries" },
        { title: "Types" },
        { title: "Courses" },
      ],
      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: $chartColors,
        noData: {
          text: "No Data found",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#043c57",
            fontSize: "14px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        tooltip: {
          fillSeriesColor: false,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
