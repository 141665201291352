<template>
  <b-card no-body>
    <b-card-header>
      <!-- <div class="d-flex justify-content-between"> -->
      <div>
        <b-card-title class="mb-1">
          Jobs Distribution
        </b-card-title>
        <b-card-sub-title>
          Job Openings by various {{ subtitleText }}
        </b-card-sub-title>
      </div>
      <u-icon icon="fi-rr-interrogation" size="24" class="text-muted cursor-pointer"
        v-b-popover.hover.left="`This section represents the distribution of all job openings by various ${subtitleText}`" />
      <!-- </div> -->
    </b-card-header>

    <!-- chart -->
    <b-card-body>
      <b-form-group class="">
        <label>Filter by</label>
        <v-select v-model="selectedFilter" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="title"
          :options="chartSelections" @input="(val) => changingFilter(val.title)" />
      </b-form-group>

      <vue-apex-charts class="mt-2" ref="jobIndustryRef" type="donut" height="300" :options="chartOptions"
        :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardHeader,
  BFormGroup, VBPopover
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import { $themeColors, $chartColors } from "@themeConfig";
import axios from "@axios";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardHeader,
    BFormGroup,
    vSelect,
  },
  props: {
    jobIndustryData: {
      type: Object,
    },
    jobTypeData: {
      type: Object,
    },
    jobCourseData: {
      type: Object,
    },
    batchValue: {
      type: Number,
    },
    downloadTrigger: {
      type: Boolean
    },
  },
  directives: {
    'b-popover': VBPopover,
  },
  methods: {
    changingFilter(val) {
      console.log(val);
      if (val == "Industries") {
        if (this.jobIndustryData) {
          // console.log(this.jobIndustryData);
          this.chartOptions.labels = this.jobIndustryData.labels;
          this.series = this.jobIndustryData.industryData;
          this.subtitleText = "Industries";
        }
      } else if (val == "Types") {
        if (this.jobTypeData) {
          // console.log(this.jobTypeData);
          this.chartOptions.labels = this.jobTypeData.labels;
          this.series = this.jobTypeData.typeData;
          this.subtitleText = "Job Functions";
        }
      } else if (val == "Courses") {
        if (this.jobCourseData) {
          // console.log(this.jobCourseData);
          this.chartOptions.labels = this.jobCourseData.labels;
          this.series = this.jobCourseData.coursesData;
          this.subtitleText = "courses";
        }
      }
      this.$refs.jobIndustryRef.refresh();
    },
  },

  watch: {
    jobIndustryData() {
      var val = this.selectedFilter;
      if (val == "Industries") {
        if (this.jobIndustryData) {
          // console.log(this.jobIndustryData);
          this.chartOptions.labels = this.jobIndustryData.labels;
          this.series = this.jobIndustryData.industryData;
          this.subtitleText = "Industries";
        }
      } else if (val == "Types") {
        if (this.jobTypeData) {
          // console.log(this.jobTypeData);
          this.chartOptions.labels = this.jobTypeData.labels;
          this.series = this.jobTypeData.typeData;
          this.subtitleText = "Job Functions";
        }
      } else if (val == "Courses") {
        if (this.jobCourseData) {
          // console.log(this.jobCourseData);
          this.chartOptions.labels = this.jobCourseData.labels;
          this.series = this.jobCourseData.coursesData;
          this.subtitleText = "courses";
        }
      }
      this.$refs.jobIndustryRef.refresh();
    },
    downloadTrigger() {
      if (this.downloadTrigger) {
        this.$refs.jobIndustryRef.updateOptions({
          chart: {
            height: '300px',
          },
          dataLabels: {
            enabled: true,
            formatter: function (val, opts) {
              console.log(opts.w.globals.series[opts.seriesIndex])
              return opts.w.globals.series[opts.seriesIndex]
            },

            style: {
              fontSize: '12px',
              fontFamily: "'Mulish' , sans-serif",
              fontWeight: 600,
              colors: ["#043c57"]
            },

          },
          legend: {
            show: true, position: 'bottom', fontSize: '10px',
            fontFamily: "'Mulish' , sans-serif",
            fontWeight: 400,
          },
        })

      } else {
        console.log('problem in else')
        this.$refs.jobIndustryRef.updateOptions({
          dataLabels: {
            enabled: false,
          },
          legend: { show: false }
        })
      }
    }
  },

  data() {
    return {
      chartData: [],
      selectedFilter: "Industries",
      subtitleText: "Industries",
      chartSelections: [
        { title: "Industries" },
        { title: "Types" },
        { title: "Courses" },
      ],
      series: [],
      chartOptions: {
        plotOptions: {
          // pie: {

          //   donut: {
          //     labels: {
          //       show: true,
          //       // name: {
          //       //   show: true,
          //       //   fontSize: '22px',
          //       //   fontFamily: "'Mulish' , sans-serif",
          //       //   color: '#043c57',
          //       //   offsetY: -10
          //       // },
          //       // value: {
          //       //   show: true,
          //       //   fontSize: '14px',
          //       //   fontFamily: "'Mulish' , sans-serif",
          //       //   color: '#043c57',
          //       //   offsetY: 16,
          //       //   formatter: function (val) {
          //       //     return val
          //       //   }
          //       // },

          //       total: {
          //         show: true,
          //         label: 'Total',
          //         fontSize: '14px',
          //         fontFamily: "'Mulish' , sans-serif",
          //         fontWeight: 600,
          //         color: '#043c57',
          //         formatter: function (w) {
          //           return w.globals.seriesTotals.reduce((a, b) => {
          //             return a + b
          //           }, 0)
          //         }
          //       }
          //     }
          //   }
          // }
        },
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: $chartColors,
        noData: {
          text: "No Data found",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#043c57",
            fontSize: "14px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        tooltip: {
          fillSeriesColor: false,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
