<template>
  <b-card no-body>
    <b-card-header class="align-items-baseline">
      <div>
        <b-card-title class="mb-1">
          Last 30 days Stats
        </b-card-title>
        <b-card-sub-title>
          Heatmap distribution on various parameters
        </b-card-sub-title>
      </div>
      <u-icon
        icon="fi-rr-interrogation"
        size="24"
        class="text-muted cursor-pointer"
        v-b-popover.hover.left="
          'This section shows the last 30 days stats of events conducted, recruiters, internships, on-campus job openings, off-campus job openings and total placements of students.'
        "
      />
    </b-card-header>

    <b-card-body class="pb-0">
      <!-- apex chart -->
      <vue-apex-charts
        ref="placementCohortRef"
        type="heatmap"
        height="300"
        :options="salesLine.chartOptions"
        :series="salesLine.series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BCardText,
  BCardSubTitle,
  VBPopover,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import axios from "@axios";

const generateData = (val, { min, max }) => {
  var arr = [];
  for (let i = 0; i < val; i++) {
    var y = Math.floor(Math.random() * (max - min)) + min;
    arr.push(y);
  }
  return arr;
};
const setDateFunc = (index) => {
  var d = new Date();
  d.setDate(d.getDate() - index);
  let formatting = { month: "2-digit", day: "2-digit" };
  return new Intl.DateTimeFormat("en-GB", formatting).format(new Date(d));
};
const dateLabelFunc = (val) => {
  let monthArr = ["Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "July",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",]
  let splitVal =  val.split("/")
  return monthArr[parseInt(splitVal[1]) - 1] + ' ' + splitVal[0];
}
export default {
  components: {
    VueApexCharts,
    BCard,
    BCardHeader,
    BCardText,
    BCardTitle,
    BCardBody,
    BCardSubTitle,
  },
  props: {
    batchValue: {
      type: Number,
    },
    downloadTrigger: {
			type: Boolean
		},
  },
  directives: {
    "b-popover": VBPopover,
  },
  watch:{
    downloadTrigger() {
			
			if (this.downloadTrigger) {
				this.$refs.placementCohortRef.updateOptions({
					dataLabels: {
						enabled: true,
						style: {
							fontSize: '14px',
							fontFamily: "'Mulish' , sans-serif",
							fontWeight: 'bold',
							colors: ["#043c57"]
						},

					},
				})
			
			} else {
				console.log('problem in else')
				this.$refs.placementCohortRef.updateOptions({
					dataLabels: {
						enabled: false,
					},
				})
			}
		}
  },
  created() {
    //changing initially
    if (this.$store.state.mainUserCodes) {
      const batch = this.batchValue;
      axios
        .post("/placement-report/loadCohortsData", { batch })
        .then((response) => {
          var firebaseData = response.data;
          this.salesLine.series = [
            { name: "Placements", data: firebaseData.placementData.data },
            { name: "On-Campus Jobs", data: firebaseData.onCampusjobsData },
            { name: "Off-Campus Jobs", data: firebaseData.offCampusjobsData },
            { name: "Internships", data: firebaseData.internsData },
            { name: "Recruiters", data: firebaseData.recruitersData },
            { name: "Events", data: firebaseData.eventsData },
          ];
          // this.salesLine.chartOptions.xaxis.categories =
          //   firebaseData.placementData.cohorts;
          this.$refs.placementCohortRef.refresh();
        });
    }

    //changing when store value changed
    this.$store.watch(
      (state) => {
        return this.$store.state.mainUserCodes;
      },
      (newVal, oldVal) => {
        const batch = this.batchValue;
        axios
          .post("/placement-report/loadCohortsData", { batch })
          .then((response) => {
            var firebaseData = response.data;
            this.salesLine.series = [
              { name: "Placements", data: firebaseData.placementData.data },
              { name: "On-Campus Jobs", data: firebaseData.onCampusjobsData },
              { name: "Off-Campus Jobs", data: firebaseData.offCampusjobsData },
              { name: "Internships", data: firebaseData.internsData },
              { name: "Recruiters", data: firebaseData.recruitersData },
              { name: "Events", data: firebaseData.eventsData },
            ];
            // this.salesLine.chartOptions.xaxis.categories =
            //   firebaseData.placementData.cohorts;
            this.$refs.placementCohortRef.refresh();
          });
      },
      { deep: true }
    );
    // console.log(this.firebaseData);
  },
  methods: {},
  data() {
    return {
      salesLine: {
        series: [],

        chartOptions: {
          chart: {
            toolbar: { show: false },
            zoom: { enabled: false },
            type: "heatmap",
            // dropShadow: {
            //   enabled: true,
            //   top: 18,
            //   left: 2,
            //   blur: 5,
            //   opacity: 0.2,
            // },
            // offsetX: -10,
          },
          dataLabels: {
            enabled: false,
          },
          noData: {
            text: "No Data found",
            align: "center",
            verticalAlign: "middle",
            offsetX: 0,
            offsetY: 0,
            style: {
              color: "#043c57",
              fontSize: "14px",
              fontFamily: "'Mulish' , sans-serif",
            },
          },
          stroke: {
            curve: "smooth",
            width: 4,
          },
          grid: {
            borderColor: "#ebe9f1",
            padding: {
              right: 20,
            },
          },
          legend: {
            show: false,
          },
          colors: [$themeColors.primary],

          xaxis: {
            labels: {
              offsetY: 5,
              style: {
                colors: "#043c57",
                fontSize: "0.857rem",
                fontFamily: "'Mulish' , sans-serif",
              },
            },
            categories: [
              setDateFunc(30),
              setDateFunc(29),
              setDateFunc(28),
              setDateFunc(27),
              setDateFunc(26),
              setDateFunc(25),
              setDateFunc(24),
              setDateFunc(23),
              setDateFunc(22),
              setDateFunc(21),
              setDateFunc(20),
              setDateFunc(19),
              setDateFunc(18),
              setDateFunc(17),
              setDateFunc(16),
              setDateFunc(15),
              setDateFunc(14),
              setDateFunc(13),
              setDateFunc(12),
              setDateFunc(11),
              setDateFunc(10),
              setDateFunc(9),
              setDateFunc(8),
              setDateFunc(7),
              setDateFunc(6),
              setDateFunc(5),
              setDateFunc(4),
              setDateFunc(3),
              setDateFunc(2),
              setDateFunc(1),
              setDateFunc(0),
            ],
          },
          yaxis: {
            tickAmount: 5,
            labels: {
              style: {
                colors: "#043c57",
                fontSize: "0.857rem",
                fontFamily: "'Mulish' , sans-serif",
              },
            },
          },
          tooltip: {
            fillSeriesColor: false,
            theme: "dark",
            style: {
              fontSize: "12px",
              fontFamily: "'Mulish' , sans-serif",
            },
            x:{
              formatter: function(value, { series, seriesIndex, dataPointIndex, w }) {
      return dateLabelFunc(value)
    }
            }
            // x: { show: false },
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
.apexcharts-canvas .apexcharts-tooltip {
	background: rgba(30, 30, 30, 0.8);
	font-weight: 600;
	font-family: "Mulish", sans-serif;

	span {
		font-weight: 800;
		color: #fff;
	}
}
.apexcharts-xaxistooltip{
  background: rgba(30, 30, 30, 0.8);
	font-weight: 600;
	font-family: "Mulish", sans-serif;
  border-radius: 8px;
  border:none;

	span {
		font-weight: 800;
		color: #fff;
	}
}
</style>
