<template>
  <b-card no-body>
    <b-card-header>
      <div class="d-flex justify-content-between">
      <div>
        <b-card-title class="mb-1">
          Internships Distribution
        </b-card-title>
        <b-card-sub-title>
          Internships by On-Campus v/s Off-Campus
        </b-card-sub-title>
      </div>
      <u-icon icon="fi-rr-interrogation" size="24" class="text-muted cursor-pointer" v-b-popover.hover.left="
        'This section shows the distribution of all the on-campus and off-campus internships.'
      " />
      </div>
    </b-card-header>

    <!-- chart -->
    <b-card-body class="mt-2 ">
      <!-- <b-form-group class="">
        <label>Filter by</label>
        <v-select
          v-model="selectedFilter"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          label="title"
          :options="chartSelections"
          @input="(val) => changingFilter(val.title)"
        />
      </b-form-group> -->

      <vue-apex-charts class="mt-xs-2" ref="internCategoryRef" type="pie" height="300" :options="chartOptions" :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardHeader,
  BFormGroup,
  VBPopover,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import vSelect from "vue-select";
import { $themeColors, $chartColors } from "@themeConfig";
import axios from "@axios";

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardHeader,
    BFormGroup,
    vSelect,
  },
  props: {
    internCategoryData: {
      type: Object,
    },

    batchValue: {
      type: Number,
    },
    downloadTrigger: {
      type: Boolean
    },
  },
  directives: {
    "b-popover": VBPopover,
  },

  watch: {
    internCategoryData() {
      if (this.internCategoryData) {
        this.chartOptions.labels = this.internCategoryData.labels;
        this.series = this.internCategoryData.categoryData;
        this.$refs.internCategoryRef.refresh();
      }
    },
    downloadTrigger() {

      if (this.downloadTrigger) {
        this.$refs.internCategoryRef.updateOptions({
          dataLabels: {
            enabled: true,
              distributed: true,
            formatter: function (val, opts) {
              console.log(opts.w.globals.series[opts.seriesIndex])
              return opts.w.globals.series[opts.seriesIndex]
            },
            style: {
              fontSize: '12px',
              fontFamily: "'Mulish' , sans-serif",
              fontWeight: 600,
              colors: ["#043c57"]
            },

          },
          legend: {
            show: true, position: 'bottom', fontSize: '10px',
            fontFamily: "'Mulish' , sans-serif",
            fontWeight: 400,
          },
        })

      } else {
        console.log('problem in else')
        this.$refs.internCategoryRef.updateOptions({
          dataLabels: {
            enabled: false,
          },
          legend: { show: false },

        })
      }
    }
  },

  data() {
    return {
      chartData: [],

      series: [],
      chartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        stroke: { width: 0 },
        colors: $chartColors,
        noData: {
          text: "No Data found",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#043c57",
            fontSize: "14px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        tooltip: {
          fillSeriesColor: false,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
