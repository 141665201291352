<template>
	<div class="placement-report-wrapper">
		<placement-report-actions class="mb-2 placement-report-actions" :batch-filter.sync="batchFilter"
			:batch-options="batchOptions" :download-filter.sync="downloadFilter" :download-options="downloadOptions"
			@download-Files="downloadFiles">
		</placement-report-actions>
		<section id="dashboard-ecommerce" style="margin-top:1.5rem" ref="placementReport">
			<b-row class="match-height placement-report-preview">
				<b-col xl="8" md="6">
					<course-wise-registration :batch-value="batchFilter" :generated-uri.sync="image1"
						:data-series="firebaseData.courseSeries" :total-students="firebaseData.totalStudents"
						:download-trigger="isDownloadSelected">
					</course-wise-registration>
				</b-col>
				<b-col xl="4" md="6">
					<company-by-industry :company-sector-data="firebaseData.companySectorData"
						:batch-value="batchFilter" :download-trigger="isDownloadSelected"></company-by-industry>
				</b-col>
				<!-- </b-row> -->
				<!-- <b-row class="match-height"> -->
				<b-col xl="4" md="6">
					<jobs-distribution :batch-value="batchFilter" :job-industry-data="firebaseData.jobIndustryData"
						:job-type-data="firebaseData.jobTypeData" :job-course-data="firebaseData.jobCoursesData"
						:download-trigger="isDownloadSelected">
					</jobs-distribution>
					<!-- <jobs-by-industry :batch-value="batchFilter" :job-industry-data="firebaseData.jobIndustryData"></jobs-by-industry> -->
				</b-col>
				<b-col xl="8" md="6">
					<jobs-by-category :batch-value="batchFilter" :job-category-data="firebaseData.jobCategoryData.data"
						:download-trigger="isDownloadSelected">
					</jobs-by-category>
				</b-col>
				<!-- </b-row> -->
				<!-- <b-row class="match-height"> -->
				<b-col xl="8" md="6">
					<placement-yearly-stat :batch-value="batchFilter"
						:placement-month-data="firebaseData.placedMonthData"
						:prev-placement-month-data="firebaseData.prevPlacedMonthData"
						:download-trigger="isDownloadSelected">
					</placement-yearly-stat>
				</b-col>
				<b-col xl="4" md="6">
					<placement-distribution :batch-value="batchFilter" :placement-industry-data="
						firebaseData.placedIndustryData
					" :placement-type-data="firebaseData.placedTypeData" :placement-course-data="firebaseData.placedCoursesData"
						:download-trigger="isDownloadSelected">
					</placement-distribution>
				</b-col>
				<!-- </b-row> -->
				<!-- <b-row class="match-height"> -->
				<b-col xl="4" md="6">
					<intern-distribution :batch-value="batchFilter"
						:intern-category-data="firebaseData.internCategoryData" :download-trigger="isDownloadSelected">
					</intern-distribution>
				</b-col>
				<b-col xl="8" md="6">
					<all-cohorts-data :batch-value="batchFilter" :download-trigger="isDownloadSelected">
					</all-cohorts-data>
				</b-col>
			</b-row>
		</section>
	</div>
</template>
<script>
import PlacementReportActions from "./PlacementReportActions.vue";
import CourseWiseRegistration from "./CourseWiseRegistration.vue";
import CompanyByIndustry from "./CompanyByIndustry.vue";

import PlacementYearlyStat from "./PlacementYearlyStat.vue";
import JobsDistribution from "./JobsDistribution.vue";
import JobsByCategory from "./JobsByCategory.vue";

import PlacementDistribution from "./PlacementDistribution.vue";
import AllCohortsData from "./AllCohortsData.vue";
import InternDistribution from "./InternshipDistribution.vue";
import store from "@/store";
import { ref, onUnmounted, watch } from "@vue/composition-api";
import axios from "@axios";
import jsPDF from 'jspdf'

import { BRow, BCol } from "bootstrap-vue";
export default {
	components: {
		PlacementReportActions,
		CourseWiseRegistration,
		CompanyByIndustry,
		PlacementYearlyStat,
		JobsDistribution,
		JobsByCategory,
		PlacementDistribution,
		AllCohortsData,
		InternDistribution,
		BRow,
		BCol,
	},
	watch: {
		batchFilter() {
			const batch = this.batchFilter;
			axios
				.post("/placement-report/loadData", { batch })
				.then((response) => {
					this.firebaseData = response.data;
				});
		},
	},
	methods: {
		downloadFiles() {
			this.isDownloadSelected = true
			this.sleep(2000).then(async () => {
				console.log("World!");
				await window.print()

				// console.log(this.image1)
				// this.pdf.addImage(this.image1, 'PNG', 5, 5, 0, 0);

			}).then(() => {
				this.isDownloadSelected = false

			});



		},
		sleep(ms) {
			return new Promise(resolve => setTimeout(resolve, ms));
		}

	},
	setup() {
		const batchFilter = ref(2022);
		const batchOptions = ref([]);
		batchOptions.value = [
			{ label: "2019", value: 2019 },
			{ label: "2020", value: 2020 },
			{ label: "2021", value: 2021 },
			{ label: "2022", value: 2022 },
		];

		batchFilter.value = new Date().getFullYear();
		const downloadFilter = ref("pdf");
		const downloadOptions = ref([]);
		downloadOptions.value = [
			// { label: "Download as CSV", value: "csv" },
			{ label: "Download as PDF", value: "pdf" },
			// { label: "Download as EXCEL", value: "xls" },
		];
		return { batchFilter, batchOptions, downloadFilter, downloadOptions };
	},
	data() {
		return {
			firebaseData: {
				courseSeries: [],
				totalStudents: 0,
				companySectorData: {},
				jobCategoryData: { data: [] },
				internCategoryData: {},
			},
			pdf: new jsPDF('p', 'pt', 'letter'),
			isDownloadSelected: false,
			image1: null
		};
	},
	created() {
		// data

		//changing initially
		if (this.$store.state.mainUserCodes) {
			const batch = this.batchFilter;
			axios
				.post("/placement-report/loadData", { batch })
				.then((response) => {
					this.firebaseData = response.data;
				});
		}

		//changing when store value changed
		this.$store.watch(
			(state) => {
				return this.$store.state.mainUserCodes;
			},
			(newVal, oldVal) => {
				const batch = this.batchFilter;
				axios
					.post("/placement-report/loadData", { batch })
					.then((response) => {
						this.firebaseData = response.data;
					});
			},
			{ deep: true }
		);
		// console.log(this.firebaseData);
	},
};
</script>
<style lang="scss">
.apexcharts-pie-label,
.apexcharts-datalabels,
.apexcharts-datalabel,
.apexcharts-datalabel-label,
.apexcharts-datalabel-value {
	filter: none;

}

.apexcharts-canvas .apexcharts-tooltip {
	background: rgba(30, 30, 30, 0.8);
	font-weight: 600;
	font-family: "Mulish", sans-serif;

	span {
		font-weight: 800;
		color: #fff;
	}
}

@page {
	margin-bottom: 90px
		/* Top margin on first page 10cm */
}

// .col-xl-8 {
// 				max-width: 100%;
// 				flex-grow: 1;
// 				width: 100% !important;

// 				.apexcharts-canvas {
// 					align-items: center;
// 					justify-content: center;
// 					width: 100% !important;
// 					flex-grow: 1;

// 					svg {
// 						align-items: center;
// 						justify-content: center;
// 						width: inherit !important;
// 						flex-grow: 1;
// 					}
// 				}
// 			}
@media print {

	// Global Styles
	body {
		background-color: transparent !important;
	}

	nav.header-navbar {
		display: none;
	}

	.main-menu {
		display: none;
	}

	.header-navbar-shadow {
		display: none !important;
	}

	.content.app-content {
		margin-left: 0;
		padding-top: 2rem !important;
	}

	footer.footer {
		display: none;
	}

	.card {
		background-color: transparent;
		box-shadow: none;
	}

	.customizer-toggle {
		display: none !important;
	}

	// Invoice Specific Styles
	.placement-report-wrapper {
		.row.placement-report-preview {
			.card {
				margin-bottom: 3rem;
			}

			.form-group {
				display: none;

			}

			.col-xl-8 {
				max-width: 100%;
				flex-grow: 1;
				width: 100% !important;

				.course-class {
					width: 100% !important;

					svg {
						width: 100% !important;

					}
				}

				::v-deep .apexcharts-canvas {
					align-items: center;
					justify-content: center;
					width: 100% !important;

					svg {
						align-items: center;
						justify-content: center;
						width: 100% !important;
					}
				}
			}

			.apexcharts-legend-marker {
				-webkit-print-color-adjust: exact;
				print-color-adjust: exact;
			}

			.fi-rr-interrogation {
				display: none;
			}
		}

		// Action Right Col
		.placement-report-actions {
			display: none;
		}
	}
}
</style>
