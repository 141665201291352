<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          Jobs Yearly Stat
        </b-card-title>
        <b-card-sub-title>Number of On-Campus and Off-Campus Jobs yearly</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->

      <!-- datepicker -->
      <div class="d-flex align-items-center">
        <u-icon
          v-b-popover.hover.left="'This section shows the total number of on-campus and off-campus job openings in this academic year'"
          icon="fi-rr-interrogation" size="24" class="cursor-pointer text-muted" />

      </div>
      <!-- datepicker -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts type="area" height="350" ref="jobyearlyStatRef" :options="lineAreaChartSpline.chartOptions"
        :series="lineAreaChartSpline.series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardBody, BCardTitle, BCardSubTitle, VBPopover
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import flatPickr from 'vue-flatpickr-component'
import { $themeColors, $chartColors } from "@themeConfig";
import "firebase/firestore";
import firebase from "firebase/app";

export default {
  components: {
    BCard,
    VueApexCharts,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    flatPickr,
  },
  directives: {
    'b-popover': VBPopover,
  },

  props: {
    jobCategoryData: {
      type: Array,
    },
    batchValue: {
      type: Number,
    },
    downloadTrigger: {
      type: Boolean
    },
  },
  watch: {
    jobCategoryData() {
      if (this.jobCategoryData) {
        // console.log(this.jobCategoryData)
        var totalJobsOncampus = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        var totalJobsOffcampus = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

        this.jobCategoryData.forEach((item) => {
          if (item.category == 'On-Campus') {
            var month = new Date(item.timestamp).getMonth()
            totalJobsOncampus[month] = totalJobsOncampus[month] + item.company
          } else {
            var month = new Date(item.timestamp).getMonth()
            // console.log(month)
            totalJobsOffcampus[month] = totalJobsOffcampus[month] + item.company
          }
        });
        // console.log(totalJobsOffcampus)
        var series = [
          { name: 'On-Campus', data: totalJobsOncampus },
          { name: 'Off-Campus', data: totalJobsOffcampus }
        ]
        this.lineAreaChartSpline.series = series
      }
    },
    downloadTrigger() {

      if (this.downloadTrigger) {
        // this.$refs.jobyearlyStatRef.updateOptions({
        // 	dataLabels: {
        // 		enabled: true,
        // 		style: {
        // 			fontSize: '14px',
        // 			fontFamily: "'Mulish' , sans-serif",
        // 			fontWeight: 'bold',
        // 			colors: ["#043c57"]
        // 		},

        // 	},
        // })
      } else {
        console.log('problem in else')
        // this.$refs.jobyearlyStatRef.updateOptions({
        // 	dataLabels: {
        // 		enabled: false,
        // 	},
        // })
      }
    }
  },
  data() {
    return {
      lineAreaChartSpline: {
        series: [
          {
            name: 'On-Campus',
            data: [],
          },
          {
            name: 'Off-Campus',
            data: [],
          },

        ],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            show: false,
            curve: 'straight',
          },
          legend: {
            show: true,
            position: 'top',
            horizontalAlign: 'left',
            fontSize: '14px',
            fontFamily: 'Mulish',
          },
          grid: {
            xaxis: {
              lines: {
                show: true,
              },
            },
          },
          tooltip: {
            fillSeriesColor: false,
            theme: "dark",
            style: {
              fontSize: "12px",
              color: "#fff",
              fontFamily: "'Mulish' , sans-serif",
            },
            x: { show: false },
            // shared: true

          },
          xaxis: {
            categories: [
              "Jan",
              "Feb",
              "Mar",
              "Apr",
              "May",
              "Jun",
              "July",
              "Aug",
              "Sep",
              "Oct",
              "Nov",
              "Dec",
            ],
          },
          yaxis: {
            // opposite: isRtl
          },
          fill: {
            opacity: 1,
            type: 'solid',
          },
          tooltip: {
            shared: false,
          },
          colors: [$themeColors.primary, $themeColors.warning],
        },
      },
    }
  },
}
</script>
