<template>
	<!-- <b-card no-body> -->
	<!-- <b-card-header class="pb-50">
      <h5>
        Filters
      </h5>
    </b-card-header> -->
	<!-- <b-card-body> -->
	<b-card no-body>

		<b-card-header class="pb-50">
			<h5>
				Filters
			</h5>
		</b-card-header>
		<b-card-body>
			<div id="placement-actions">
				<div class="w-100 d-flex align-items-center justify-content-between">
					<div  class="mb-md-0 mb-2 flex-fill">
						<label>Batch</label>
						<v-select style="    background-color: #fff;" placeholder="Select batch"
							:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="batchFilter"
							:options="batchOptions"  :reduce="(val) => val.value"
							@input="(val) => $emit('update:batchFilter', val)" />
					</div>

					<div   class="mb-md-0 mb-2 justify-content-end " style="width:35%">
						<label>Download Report</label>

						<b-input-group>
							<v-select placeholder="Select report type"
								:dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="downloadFilter"
								:options="downloadOptions" class="form-control" :reduce="(val) => val.value"
								@input="(val) => $emit('update:downloadFilter', val)" />
							<b-input-group-append>
								<b-button variant="primary" class="" @click="$emit('download-Files')">
									<!-- <i class="fi fi-rr-download mr-50"></i> -->
									<span class="text-nowrap">Download</span>
								</b-button>
							</b-input-group-append>
						</b-input-group>
					</div>
				</div>
			</div>

		</b-card-body>
	</b-card>
</template>
<script>
import {
	BCard,
	BCardHeader,
	BCardBody,
	BRow,
	BCol,
	BButton,
	BInputGroup,
	BFormInput,
	BInputGroupAppend,
} from "bootstrap-vue";
import vSelect from "vue-select";

import { watch } from "@vue/composition-api";

export default {
	components: {
		BRow,
		BCol,
		BCard,
		BCardHeader,
		BCardBody,
		vSelect,
		BButton,
		BInputGroup,
		BFormInput,
		BInputGroupAppend,
	},

	// watch : {
	//   batchOptions : function () {
	//     console.log('batchOptions changed');
	//   }
	// },
	props: {
		batchFilter: {
			type: [Number, null],
			default: null,
		},
		batchOptions: {
			type: Array,
			required: true,
		},
		downloadFilter: {
			type: [String, null],
			default: null,
		},
		downloadOptions: {
			type: Array,
			required: true,
		},
	},
	
};
</script>

<style lang="scss">
#placement-actions {
	.v-select{
		max-width: 320px;
	}
	.form-control {
		padding: 0px;
		height: min-content;

		//height:42px;
		.vs__dropdown-toggle {
			border: none;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
	}
	// .input-group{
	// 	max-width:380px;
	// }
}

</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
