<template>
	<b-card no-body>
		<b-card-header>
			<div>
				<b-card-title class="mb-1">
					Course Wise Registration
				</b-card-title>
				<b-card-sub-title>
					Number of Registrations in each course
				</b-card-sub-title>
			</div>
			<u-icon icon="fi-rr-interrogation" size="24" class="text-muted cursor-pointer" v-b-popover.hover.left="
				`This section indicates the total number of students registered from different courses for placements in ${batchValue} till today.`
			" />
		</b-card-header>
		<b-card-body>
			<div class="mr-2 mb-2 justify-content-center">
				<h3 class="font-weight-bolder d-flex flex-column  ">
					<span class="font-medium-1">
						Total Students in {{ batchValue }}
					</span>
					<span class="text-primary justify-content-center">
						{{ kFormatter(totalStudents) }}
					</span>
				</h3>
			</div>
			<!-- apex chart -->
			<vue-apex-charts ref="courseWiseRef" type="bar" height="240" :options="salesBar.chartOptions" width="100%"
				:series="dataSeries" class="text-center justify-content-center course-class" />
		</b-card-body>
	</b-card>
</template>

<script>
import {
	BCard,
	BRow,
	BCol,
	BButton,
	BDropdown,
	BDropdownItem,
	BProgress,
	BCardText,
	BCardBody,
	BCardHeader,
	BCardTitle,
	BCardSubTitle,
	VBPopover,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import { $themeColors, $chartColors } from "@themeConfig";
import { kFormatter } from "@core/utils/filter";
import axios from "@axios";
import jsPDF from 'jspdf'

export default {
	components: {
		VueApexCharts,
		BCard,
		BRow,
		BCol,
		BButton,
		BCardText,
		BDropdown,
		BDropdownItem,
		BProgress,
		BCardSubTitle,

		BCardBody,
		BCardHeader,
		BCardTitle,
	},
	directives: {
		Ripple,
		"b-popover": VBPopover,
	},
	props: {
		batchValue: {
			type: Number,
		},
		dataSeries: {
			type: Array,
		},
		totalStudents: {
			type: Number,
		},
		downloadTrigger: {
			type: Boolean
		},
		generatedUri: {}

	},
	watch: {
		dataSeries() {
			// console.log(this.dataSeries);
			this.$refs.courseWiseRef.refresh();
		},
		downloadTrigger() {
			console.log(this.$refs.courseWiseRef)

			if (this.downloadTrigger) {
				this.$refs.courseWiseRef.updateOptions({
					dataLabels: {
						enabled: true,
						style: {
							fontSize: '14px',
							fontFamily: "'Mulish' , sans-serif",
							fontWeight: 'bold',
							colors: ["#043c57"]
						},

					},
				})
				console.log(this.$refs.courseWiseRef.chart.windowResizeHandler())
				this.$refs.courseWiseRef.chart.windowResizeHandler()

			} else {
				console.log('problem in else')
				this.$refs.courseWiseRef.updateOptions({
					dataLabels: {
						enabled: false,
					},
				})
			}
		}
	},
	data() {
		return {
			avgData: {},
			// pdf:new jsPDF(),
			salesBar: {
				chartOptions: {
					chart: {
						// sparkline: { enabled: true },
						toolbar: { show: false },
						redrawOnWindowResize: true,
						redrawOnParentResize: true,
						events: {
							updated: function (chartContext, config) {
								// ...
								// console.log(chartContext)
								// console.log(config)
								// config.globals.svgWidth(val)
								// 21cm 29.7cm
								var width = '700px'
								// this.$refs.courseWiseRef
								config.globals.svgWidth = width
								console.log(config.globals.svgWidth)
							}
						}


					},
					// grid: {
					// 	show: false,
					// 	padding: {
					// 		left: 0,
					// 		right: 0,
					// 	},
					// },
					legend: {
						show: false,
					},
					states: {
						hover: {
							filter: "none",
						},
					},
					colors: $chartColors,
					noData: {
						text: "No Data found",
						align: "center",
						verticalAlign: "middle",
						offsetX: 0,
						offsetY: 0,
						style: {
							color: "#043c57",
							fontSize: "14px",
							fontFamily: "'Mulish' , sans-serif",
						},
					},
					plotOptions: {
						bar: {
							columnWidth: "45%",
							distributed: true,
							endingShape: "flat",
							borderRadius: 16,
						},
					},
					dataLabels: {
						enabled: false,
					},
					tooltip: {
						x: { show: true },

						fillSeriesColor: false,
						theme: "dark",
						style: {
							fontSize: "12px",
							fontFamily: "'Mulish' , sans-serif",
						},
					},
					xaxis: {
						labels: {
							style: {
								colors: "#043c57",
								fontSize: "1rem",
								fontFamily: "'Mulish' , sans-serif",
							},
						},
						axisTicks: {
							show: false,
						},

						axisBorder: {
							show: false,
						},
						tickPlacement: "on",
					},
					yaxis: {
						tickAmount: 5,
						labels: {
							style: {
								colors: "#043c57",
								fontSize: "1rem",
								fontFamily: "'Mulish' , sans-serif",
							},
						},
					},
					grid: {
						borderColor: "#efefef",
						xaxis: {
							lines: {
								show: true,
							},
						},
						// padding: {
						//   top: -20,
						//   bottom: -10,
						//   left: 20,
						// },
					},
					// xaxis: {
					// 	type: "numeric",
					// },
				},
			},
		};
	},

	methods: {
		updateWidthVal() {

		},
		kFormatter,
		handleDownload(type) {
			// 	var ctx = chart.ctx;

			// 	    this.ctx = ctx
			// this.w = ctx.w

			// const w = this.w
			// const exprt = new Exports(this.ctx)
			// const exprt = ctx.exports
			// switch (type) {
			//   case 'svg':
			//     exprt.exportToSVG(this.ctx)
			//     break
			//   case 'png':
			//     exprt.exportToPng(this.ctx)
			//     break
			//   case 'csv':
			//     exprt.exportToCSV({
			//       series: w.config.series,
			//       columnDelimiter: w.config.chart.toolbar.export.csv.columnDelimiter
			//     })
			//     break
			// }
		}

	},
};
</script>
<style lang="scss" >
.course-class {
	width: 100% !important;

	svg {
		width: 100% !important;

	}
}

// @media print {

// 	.apexcharts-canvas {
// 		align-items: center;
// 		justify-content: center;
// 		width: 100% !important;

// 		svg {
// 			align-items: center;
// 			justify-content: center;
// 			width: 100% !important;
// 		}
// 	}
// }
</style>
