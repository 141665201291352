<template>
  <b-card no-body>
    <b-card-header>
      <div>
        <b-card-title class="mb-1">
          Recruiter Distribution
        </b-card-title>
        <b-card-sub-title class="">
          Companies by various industries
        </b-card-sub-title>
      </div>
      <u-icon icon="fi-rr-interrogation" size="24" class="text-muted cursor-pointer"
        v-b-popover.hover.left="'This section represents the distribution of all recruiters in the various industries'" />
    </b-card-header>

    <!-- chart -->
    <b-card-body class="mt-2">
      <!-- <chartjs-component-polar-area-chart
        v-if="polarChartDataSeries"
        :height="350"
        :data="polarChartDataSeries"
        :options="polarChart.options"
      /> -->
      <vue-apex-charts ref="companyIndustry" type="donut" height="300" :options="companyChartOptions"
        :series="series" />
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BCardHeader, VBPopover
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors, $chartColors } from "@themeConfig";
import axios from "@axios";



export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardSubTitle,
    BCardBody,
    BCardHeader,
  },
  props: {
    companySectorData: {
      type: Object,
    },
    batchValue: {
      type: Number,
    },
    downloadTrigger: {
      type: Boolean
    },
  },
  directives: {
    'b-popover': VBPopover,
  },
  watch: {
    companySectorData() {
      if (this.companySectorData) {
        // this.chartData = this.companySectorData;
        // this.modifyData();
        // console.log(this.companySectorData);
        this.companyChartOptions.labels = this.companySectorData.labels;
        this.series = this.companySectorData.companyData;
        this.$refs.companyIndustry.refresh();
      }
    },
    downloadTrigger() {

      if (this.downloadTrigger) {
        this.$refs.companyIndustry.updateOptions({
          dataLabels: {
            enabled: true,
            distributed: true,
            formatter: function (val, opts) {
              console.log(opts.w.globals.series[opts.seriesIndex])
              return opts.w.globals.series[opts.seriesIndex]
            },
            style: {
              fontSize: '12px',
              fontFamily: "'Mulish' , sans-serif",
              fontWeight: 600,
              colors: ["#043c57"]
            },

          },
          legend: {
            show: true, position: 'bottom', fontSize: '10px',
            fontFamily: "'Mulish' , sans-serif",
            fontWeight: 400,
          },
        })

      } else {
        console.log('problem in else')
        this.$refs.companyIndustry.updateOptions({
          dataLabels: {
            enabled: false,
          },
          legend: { show: false }
        })
      }
    }
  },

  data() {
    return {
      chartData: [],
      // polarChartDataSeries :{},
      series: [],
      companyChartOptions: {
        chart: {
          toolbar: {
            show: false,
          },
        },
        labels: [],
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        stroke: { width: 0 },
        colors: $chartColors,
        tooltip: {
          fillSeriesColor: false,
          theme: "dark",
          style: {
            fontSize: "12px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        noData: {
          text: "No Data found",
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            color: "#043c57",
            fontSize: "14px",
            fontFamily: "'Mulish' , sans-serif",
          },
        },
        states: {
          normal: {
            filter: {
              type: "none",
              value: 0,
            },
          },
          hover: {
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
          active: {
            allowMultipleDataPointsSelection: false,
            filter: {
              type: "darken",
              value: 0.35,
            },
          },
        },
      },
    };
  },
};
</script>
<style lang="scss">
//color pallette
</style>
